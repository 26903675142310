import * as Yup from "yup";
import ConsultationFormModel from "./ConsultationFormModel";

const {
  formField: {
    detail,
    diagnosis,
    specialties,
    certificates: { institution, indications, duration, unit},
    studies,
    medications,
    reason
    }
} = ConsultationFormModel;

// Only add our `optional` method to object types
Yup.addMethod(Yup.object, "optional", function(
  isOptional = true,
  defaultValue = undefined
) {
  return this.transform(function(value) {
    // If false is passed, skip the transform
    if (!isOptional) return value;

    // If any child property has a value, skip the transform
    if (
      value &&
      Object.values(value).some(v => !(v === null || v === undefined || v === '' || v === []))
    ) {
      return value;
    }

    return defaultValue;
  })
  // Remember, since we're dealing with the `object` 
  // type, we have to change the default value
  .default(defaultValue);
});

/* Useful array unique
Yup.addMethod(Yup.array, 'unique', function (message, mapper = a => a) {
  return this.test('unique', message, function (list) {
    return list?.length === new Set(list.map(mapper))?.size;
  });
}); */

const ValidationSchema = 
  Yup.object().shape({
    detail: Yup.string().required(detail.requiredErrorMsg),
    diagnosis: Yup.string().required(diagnosis.requiredErrorMsg),
    reason: Yup.string()
         .max(2000, reason.maxErrorMsg)
         .required(reason.requiredErrorMsg),
    certificates: Yup.object().shape({
      diagnosis: Yup.string().required(diagnosis.requiredErrorMsg),
      indications: Yup.string().required(indications.requiredErrorMsg),
      institution: Yup.string().required(institution.requiredErrorMsg),
      duration: Yup.number().required(unit.requiredErrorMsg),
      unit: Yup.string().required(duration.requiredErrorMsg),
    }).optional(),
    studies: Yup.object().shape({
      diagnosis: Yup.string().required(diagnosis.requiredErrorMsg),
      studyNames: Yup.array().min(1, studies.requiredErrorMsg)
    }).optional(),
    specialties: Yup.object().shape({
      diagnosis: Yup.string().required(diagnosis.requiredErrorMsg),
      specialtyNames: Yup.array().of(
        Yup.object().shape({
          id: Yup.string(),
          name: Yup.string()
        })
      ).min(1, specialties.requiredErrorMsg)
    }).optional(),
    medications: Yup.array().of(
      Yup.object().shape({
        id: Yup.string(),
        name: Yup.string(),
        diagnosis: Yup.string(),
        posology: Yup.string().nullable(),
        quantity: Yup.number(medications.quantity.numberError)
      })
    ).optional(),
    other_indications: Yup.array()
          .of(
            Yup.object().shape({
              id: Yup.number().required("El ID es requerido"),
              text: Yup.string()
                .min(1, "El texto no puede estar vacío")
                .required("El texto es requerido"),
            })
          ).optional()
  });

export default ValidationSchema;
