import React, {
  createContext,
  useContext,
  useReducer,
} from "react";


export const ProfessionalLogContext = createContext();

export const ProfessionalLogState = ({ reducer, initialState, children }) => {
  return (
    <ProfessionalLogContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </ProfessionalLogContext.Provider>
  );
};

export const useProfessionalLogState = () => useContext(ProfessionalLogContext);
