import { Box } from "@material-ui/core";
import { Brightness1 } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useProfessionalLogState } from "../../../context/profesionalPage/professionalLog/professionalLogState";
import "./activeProfessionalsListStyle.scss";
import { GetActiveProfessionalsList } from "../../../services/medilinePrepaidService";
import { useStateAuthValue } from "../../../context/Auth/AuthState";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { act } from "react";
const ActiveProfessionalsList = () => {
  const [activeProfessionals, setActiveProfessionals] = useState([]);
  const [logedOutProfessionals, setLogedOutProfessionals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [{ userData, userToken }] = useStateAuthValue();
  const getPlan = async () => {
    await GetActiveProfessionalsList(userToken).then((res) => {
      console.log(res.data);
      setLoading(false);
      setActiveProfessionals(res.data?.activeProfessionals);
      setLogedOutProfessionals(res.data?.logedOutProfessionals);
    });
  };
  useEffect(() => {
    const interval = setInterval(() => {
      getPlan();
    }, 2000); // Se ejecuta cada 2 segundos

    return () => clearInterval(interval); // Limpieza al desmontar
  }, [userToken]);
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day}/${month} ${hours}:${minutes}`;
  };
  return (
    <Box className="active-professionals-list">
      <Box className="header">
        <h2>Profesional</h2>
        <h2>Especialidad</h2>
        <h2>Ult. Actividad</h2>
      </Box>
      {loading ? (
        <Box width={"30rem"}>
          <LoadingSpinner></LoadingSpinner>
        </Box>
      ) : (
        <>
          {activeProfessionals?.map(({ event, id, name, last_active,specialities }) => {
            let isActive = event != "LogOut" && event != "EndedUnexpectedly";
            let speciality = "";
            switch (specialities) {
              case 'b3792b24-85a8-4373-8b06-c0d48ec744a4':
                speciality = "Clinica Medica"
                break;
               case 'e09fa8d7-77f3-4c63-93e1-19e6caf1f6fc':
                speciality = "Pediatría"
                break;
              default:
                break;
            }
            return (
              <Box className="item" key={id}>
                <span>{name}</span>
                <span>{speciality}</span>
                <Box className="last-active">
                  <span>{formatDate(last_active)}</span>
                  <Brightness1
                    style={{ color: isActive ? "#00cc00" : "#ff0000" }}
                  ></Brightness1>
                </Box>
              </Box>
            );
          })}
          {!activeProfessionals.length && "-"}
        </>
      )}
      <Box className="header">
        <h2>Profesional</h2>
        <h2>Especialidad</h2>
        <h2>Ult. Cierre Sesión</h2>
      </Box>
      {loading ? (
        <Box width={"20rem"}>
          <LoadingSpinner></LoadingSpinner>
        </Box>
      ) : (
        <>
          {logedOutProfessionals?.map(({ id, name, last_active,last_logout }) => {
            return (
              <Box className="item disabled" key={id}>
                <span>{name}</span>
                <Box>{last_logout ? formatDate(last_logout) :formatDate(last_active)}</Box>
              </Box>
            );
          })}
          {!logedOutProfessionals.length && (
            <Box
              width={"1rem"}
              height={"2px"}
              borderRadius={"10px"}
              bgcolor={"#002c54"}
              mt={"1rem"}
            ></Box>
          )}
        </>
      )}
      {/* //   <Box className="item">
    //     <span>Professional Uno</span>
    //     <Box>
    //       <span>28/06 15:49 </span>
    //       <Brightness1 style={{ color:  }}></Brightness1>
    //     </Box>
    //   </Box>
    //   <Box className="item">
    //     <span>Professional Dos</span>
    //     <Box>
    //       <span>28/06 15:45 </span>
    //       <Brightness1 style={{ color: "#ff0000" }}></Brightness1>
    //     </Box>
    //   </Box> */}
    </Box>
  );
};

export default ActiveProfessionalsList;
