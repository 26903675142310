import { over } from "stompjs";
import SockJS from "sockjs-client";
import { GetSessionToken } from "../../../services/authService";

export const professionalLogInitialState = {
  stompClient: null,
  activeProfessionals: [],
};
let userData = null;
const reconnectDelay = 2000;
let fakePositive = true;
let isReconnecting = false;

const ProfessionalLogReducer = (state, action) => {
  const onPrivateMessageProfessional = (message) => {
    if (userData?.active_role === "PROFESSIONAL" && GetSessionToken()) {
      switch (message?.body) {
        case "ping":
          fakePositive = false;
          state.stompClient.send(
            "/app/pong",
            {},
            JSON.stringify({
              professionalId: userData.email,
              command: "Pong",
            })
          );
          break;
        case "conexion-lost":
          if (state.stompClient && state.stompClient.connected) {
            state.stompClient.disconnect(() => {
              console.error("Desconexion por inactividad");
            });
            action.payload.message.command = "InitialPong";

            attemptReconnect();
          }
          break;
        case "conexion-ended":
          if (state.stompClient && state.stompClient.connected) {
            state.stompClient.disconnect(() => {
              console.error("Desconexion por inactividad");
              // isConnectedWS = false;
              // attemptReconnect();
            });
          }
          break;

        default:
          break;
      }
    }
    return state;
  };
  const checkFakePositive = () => {
    if (fakePositive && !isReconnecting) {
      ProfessionalLogReducer(state, action);
    }
  };
  const connectWS = (state, action) => {
    const UrlWS = process.env.REACT_APP_WS_PARAMETRICS_URL;
    let { mail, userData: _userData, message: initialMessage } = action.payload;
    userData = _userData;
    const socket = new SockJS(UrlWS);
    fakePositive = true;
    if (state.stompClient && state.stompClient.connected) {
      state.stompClient.disconnect(() => {
        console.error("Desconexion por inactividad");
      });
    }
    state.stompClient = over(socket);
    state.stompClient.connect(
      {},
      (frame) => {
        // Conexión exitosa
        // isConnectedWS = true;
        // isReconnecting = false; // Reseteamos la bandera porque ya estamos conectados
        console.log("Conexión WS exitosa:", frame);
        fakePositive = false;
        // dispatch({
        //   type: "SET_STOMP_CLIENT",
        //   payload: _stompClient.current,
        // });

        state.stompClient.subscribe(
          "/user/" + mail + "/ping",
          onPrivateMessageProfessional
        );

        // dispatch({
        //   type: "SEND_MESSAGE",
        //   payload: {
        //     message: {
        //       professionalId: userData.email,
        //       command: "InitialPong",
        //     },
        //   },
        // });
        state.stompClient.send("/app/pong", {}, JSON.stringify(initialMessage));
      },
      (error) => {
        // Manejar error de conexión y reconectar
        console.error("Error en la conexión WS:", error);
        // isConnectedWS = false;
        // fakePositive = false;
        attemptReconnect();
      }
    );
    setTimeout(() => {
      checkFakePositive();
    }, reconnectDelay * 2);
    return state;
  };
  const attemptReconnect = () => {
    if (!isReconnecting) {
      if (state.stompClient?.connected) state.stompClient.disconnect(() => {});
      state.stompClient = null;
      isReconnecting = true; // Marcamos que estamos intentando reconectar
      console.log("Intentando reconectar...");
      setTimeout(() => {
        isReconnecting = false;
        ProfessionalLogReducer(state, action);
      }, reconnectDelay);
    }
  };
  switch (action.type) {
    case "SET_STOMP_CLIENT":
      return {
        ...state,
        stompClient: action.payload,
      };

    case "SEND_MESSAGE":
      let { message } = action.payload;
      state.stompClient?.send("/app/pong", {}, JSON.stringify(message));
      return state;
    case "CONNECT_WS":
      if (userData?.active_role != "PROFESSIONAL" && !GetSessionToken())
        return state;
      connectWS(state, action);
    default:
      return state;
  }
};

export default ProfessionalLogReducer;
